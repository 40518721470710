import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import SectionHeadline from '@sprinx/react-mui-components/SectionHeadline';
import { StandardDivProps } from '@sprinx/react-mui-components/StandardDiv';
import withThemeProps from '@sprinx/react-mui-components/withThemeProps';
import clsx from 'clsx';
import React, { useState } from 'react';
import { useRecoilValue } from 'recoil';
import { isMasterScopeState } from '../../../api/appState';
import { shoppingCustomerDeliveryPlacesSelector, shoppingCustomerSelector } from '../../../api/shopping';
import useTranslateWithFallback from '../../../hooks/useTranslateWithFallback';
import CustomerProfilePageSubsidiaryUsersList from './CustomerProfilePageSubsidiaryUsersList';
import { CustomerProfilePageNewBranchPopup } from './CustomerProfilePageNewBranchPopup';

export type CustomerProfilePageSubsidiaryUsersProps = StandardDivProps<CustomerProfilePageSubsidiaryUsersClassKey>;

export type CustomerProfilePageSubsidiaryUsersClassKey = 'root' | 'allBranchesLoaded' | 'loadMoreBranches';

const themeSettings = { name: 'CustomerProfilePageSubsidiaryUsers' };
const useStyles = makeStyles(
  // (theme: Theme) =>
  createStyles<CustomerProfilePageSubsidiaryUsersClassKey, {}>({
    root: { marginTop: 40 },
    allBranchesLoaded: {
      marginTop: 20,
    },
    loadMoreBranches: {
      marginTop: 20,
    },
  }),
  themeSettings,
);

const CustomerProfilePageSubsidiaryUsers = React.forwardRef<HTMLDivElement, CustomerProfilePageSubsidiaryUsersProps>(
  ({ className, classes: pClasses }, ref) => {
    const classes = useStyles({ classes: pClasses });
    const [showNewBranchPopup, setShowNewBranchPopup] = useState(false);

    const places = useRecoilValue(shoppingCustomerDeliveryPlacesSelector);
    const customer = useRecoilValue(shoppingCustomerSelector);
    const isMasterScope = useRecoilValue(isMasterScopeState);
    const [page, setPage] = useState<Number>(Number(6));

    const handleNextPage = () => {
      setPage((prevState) => {
        return Number(prevState) + 7;
      });
    };
    const t = useTranslateWithFallback();

    if (!isMasterScope) return null;

    return (
      <>
        <div ref={ref} className={clsx(classes.root, className)}>
          <SectionHeadline title={t('customerProfilePage/branchesUsers')} />
          <Button variant='contained' color='primary' onClick={() => setShowNewBranchPopup(true)}>
            {t('customerProfilePage/addNewBranch')}
          </Button>

          {(places || [])
            .filter(({ id }) => id !== customer?.id)
            .slice(0, Number(page))
            .map((subsidiary, index) => (
              <CustomerProfilePageSubsidiaryUsersList subsidiary={subsidiary} key={index} />
            ))}

          {(places || []).length > Number(page) ? (
            <Button className={classes.loadMoreBranches} variant='contained' color='primary' onClick={handleNextPage}>
              {t('customerProfilePage/loadMoreBranches')}
            </Button>
          ) : (
            <Typography className={classes.allBranchesLoaded}>{t('customerProfilePage/allBranchesLoaded')}</Typography>
          )}
        </div>
        <CustomerProfilePageNewBranchPopup open={showNewBranchPopup} onClose={() => setShowNewBranchPopup(false)} />
      </>
    );
  },
);

CustomerProfilePageSubsidiaryUsers.displayName = 'CustomerProfilePageSubsidiaryUsers';

export default withThemeProps(themeSettings)(CustomerProfilePageSubsidiaryUsers);
