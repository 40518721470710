import { createStyles, makeStyles } from '@material-ui/core';
import withThemeProps from '@sprinx/react-mui-components/withThemeProps';
import React from 'react';
import { projectName } from '../../config';

const themeSettings = { name: 'LoginPageLogo' };

export type LoginPageLogoProps = {
  className?: string;
};

export type LoginPageLogoClassNameKey = 'root';

const useStyles = makeStyles(() =>
  createStyles<LoginPageLogoClassNameKey, {}>({
    root: {
      maxWidth: '310px',
      maxHeight: '141px',
      width: '100%',
      height: '100%',
    },
  }),
);

const LoginPageLogo = React.forwardRef<HTMLImageElement, LoginPageLogoProps>(() => {
  const classes = useStyles();
  return <img src={`/${projectName}/mainLogo.png`} alt='logo' className={classes.root} />;
});

LoginPageLogo.displayName = 'LoginPageLogo';

export default withThemeProps(themeSettings)(LoginPageLogo);
