import FormikExtended from '@sprinx/react-forms/FormikExtended';
import WrapDialogForm from '@sprinx/react-mui-components/WrapDialogForm';
import TextField from '@sprinx/react-mui-fields/TextField';
import React, { ReactNode, useCallback } from 'react';
import { FC } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { ApplicationAuthState } from '../../../@sprinx/react-after-razzle/stateStore';
import { apiClientState, authState, authUserState } from '../../../api/appState';
import useTranslateWithFallback from '../../../hooks/useTranslateWithFallback';

export type CustomerProfileUpdatePhoneDialogProps = {
  onClose: () => void;
  open: boolean;
  title?: ReactNode;
};

type CustomerProfileUpdatePhoneDialogFormValues = {
  phone: string;
};

type CustomerProfileUpdatePhoneDialogFormErrors = {
  phone?: string;
};

type CustomerProfileUpdatePhoneDialogFormTouched = {
  phone?: boolean;
};

const CustomerProfileUpdatePhoneDialog: FC<CustomerProfileUpdatePhoneDialogProps> = ({ open, onClose, title }) => {
  const user = useRecoilValue(authUserState);
  const setAuthState = useSetRecoilState(authState);
  const t = useTranslateWithFallback();
  const apiClient = useRecoilValue(apiClientState);

  const handleSave = useCallback(
    async ({ phone }: CustomerProfileUpdatePhoneDialogFormValues) => {
      try {
        await apiClient.post('/v1/users/update-phone', { phone, userId: user?.id });
        setAuthState((prev) =>
          prev ? ({ ...prev, user: { ...prev.user, phone } } as ApplicationAuthState<any>) : undefined,
        );
      } catch (e) {
        console.error(e);
      }
    },
    [apiClient, setAuthState, user?.id],
  );

  if (!open) return <></>;

  return (
    <FormikExtended<
      CustomerProfileUpdatePhoneDialogFormValues,
      CustomerProfileUpdatePhoneDialogFormErrors,
      CustomerProfileUpdatePhoneDialogFormTouched
    >
      onFormReleased={async () => {
        onClose();
      }}
      onSave={handleSave}
      initialValues={{
        phone: user?.phone ?? '',
      }}
      validationSchema={{
        phone: { type: 'phone', empty: false },
      }}
    >
      {(formikProps) => (
        <WrapDialogForm
          {...formikProps}
          dialogTitle={title ?? t('customerProfilePage/updatePhone/dialogTitle')}
          open={open}
          onClose={onClose}
          // variant='flat-no-gutters'
          dialogProps={{
            // buttonSaveLabel: '',
            fullWidth: true,
            maxWidth: 'sm',
          }}
          render={() => {
            const { errors, onChange: handleChange, values } = formikProps;
            return (
              <>
                <TextField
                  label={t('common/phone')}
                  helperText={''}
                  value={values.phone}
                  error={errors.phone}
                  onChange={handleChange('phone')}
                  fullWidth
                />
              </>
            );
          }}
        />
      )}
    </FormikExtended>
  );
};

export default CustomerProfileUpdatePhoneDialog;
