import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Paper from '@material-ui/core/Paper';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CheckCircleOutline from '@material-ui/icons/CheckCircleOutline';
import EditIcon from '@material-ui/icons/Edit';
import HighlightOff from '@material-ui/icons/HighlightOff';
import PasswordIcon from '@material-ui/icons/VpnKeyOutlined';
import Loader from '@sprinx/react-loader/Loader';
import DotJoined from '@sprinx/react-mui-components/DotJoined';
import IconButton from '@sprinx/react-mui-components/IconButtonWithTooltip';
import List from '@sprinx/react-mui-components/ListPretty';
import { StandardDivProps } from '@sprinx/react-mui-components/StandardDiv';
import withThemeProps from '@sprinx/react-mui-components/withThemeProps';
import PriceFieldPreviewNaked from '@sprinx/react-mui-fields/PriceFieldPreviewNaked';
import clsx from 'clsx';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { apiClientState, currencyState } from '../../../api/appState';
import UserSetPasswordDialog from '../../../components/UserSetPasswordDialog';
import useTranslateWithFallback from '../../../hooks/useTranslateWithFallback';
import CustomerProfilePageCreateSubsidiaryUserButton from './CustomerProfilePageCreateSubsidiaryUserButton';
import CustomerProfilePageUserEditDialog from './CustomerProfilePageUserEditDialog';
import useSubsidiaryUsersList, { SubsidiaryUser } from './useSubsidiaryUsersList';

export interface CustomerProfilePageSubsidiaryUsersListProps
  extends StandardDivProps<CustomerProfilePageSubsidiaryUsersListClassKey> {
  subsidiary: {
    externals: string[];
    id: string;
    name: string;
    officeName: string;
    postaddresses: {
      place: {
        full: string;
      };
      type: string;
    }[];
  };
}

export type CustomerProfilePageSubsidiaryUsersListClassKey =
  | 'root'
  | 'actions'
  | 'backgroundRed'
  | 'backgroundGreen'
  | 'deactivatedUser';

const themeSettings = { name: 'CustomerProfilePageSubsidiaryUsersList' };
const useStyles = makeStyles(
  createStyles<CustomerProfilePageSubsidiaryUsersListClassKey, {}>({
    root: {
      marginBlockStart: '40px',
      border: '1px solid #E0E0E0',
      padding: '20px',
      borderRadius: '10px',
      backgroundColor: '#f3f3f3',
    },
    actions: { display: 'flex' },
    deactivatedUser: {
      background: '#DCDCDC',
    },
    backgroundRed: {
      '&:hover': {
        borderRadius: '30px',
        background: 'rgba(255, 35, 64, 0.7)',
      },
    },
    backgroundGreen: {
      '&:hover': {
        borderRadius: '30px',
        background: 'rgba(135, 211, 124, 0.7)',
      },
    },
  }),
  themeSettings,
);

const CustomerProfilePageSubsidiaryUsersList = React.forwardRef<
  HTMLDivElement,
  CustomerProfilePageSubsidiaryUsersListProps
>(({ className, classes: pClasses, subsidiary }, ref) => {
  const classes = useStyles({ classes: pClasses });
  const { id, name, officeName, postaddresses } = subsidiary;
  const [loading, reloadUsers, users] = useSubsidiaryUsersList(id);
  const currency = useRecoilValue(currencyState);
  const apiClient = useRecoilValue(apiClientState);

  const [editDialogUser, setEditDialogUser] = React.useState<SubsidiaryUser | undefined>(undefined);
  const [passwordDialogUser, setPasswordDialogUser] = React.useState<SubsidiaryUser | null>(null);
  const handleCloseEditUserDialog = React.useCallback(() => setEditDialogUser(undefined), [setEditDialogUser]);
  const handleClosePasswordDialog = React.useCallback(() => setPasswordDialogUser(null), [setPasswordDialogUser]);
  const sortedUsers = [...users].sort((a) => (a.disabled ? 1 : -1));
  const address = postaddresses.find((address) => address.type === 'HQ')?.place.full || null;

  const updateUserStatus = React.useCallback(
    async (clientId, userId, disabled) => {
      await apiClient.post<any, { clientId: string; disabled: boolean; userId: string }>('/v1/users/updateUserStatus', {
        clientId,
        userId,
        disabled,
      });
      reloadUsers();
    },
    [apiClient, reloadUsers],
  );
  const t = useTranslateWithFallback();

  return (
    <div ref={ref} className={clsx(classes.root, className)}>
      <Typography variant='h6'>{`${officeName || name}${address != null ? ` - ${address}` : ''}`}</Typography>

      <Paper>
        <Loader loading={loading}>
          <List>
            {(sortedUsers && Array.isArray(sortedUsers) ? sortedUsers : []).map((user, idx) => (
              <ListItem key={idx} className={!user.disabled ? '' : classes.deactivatedUser}>
                <ListItemText
                  primary={user.name}
                  secondary={
                    <DotJoined>
                      {user.username}
                      {/* {user.email} */}
                      {user.orderTotalLimit ? (
                        <>
                          Limit <PriceFieldPreviewNaked currency={currency} value={user.orderTotalLimit} />
                        </>
                      ) : null}
                    </DotJoined>
                  }
                />
                <ListItemSecondaryAction className={classes.actions}>
                  <IconButton
                    label={t('customerProfilePage/changePassword/label')}
                    onClick={() => setPasswordDialogUser(user)}
                  >
                    <PasswordIcon />
                  </IconButton>
                  <IconButton label={t('customerProfilePage/changeData/label')} onClick={() => setEditDialogUser(user)}>
                    <EditIcon />
                  </IconButton>
                  {!user.disabled ? (
                    <IconButton
                      label={t('app/deactivateUser')}
                      className={classes.backgroundRed}
                      onClick={() => updateUserStatus(user.client, user.id, true)}
                    >
                      <HighlightOff />
                    </IconButton>
                  ) : (
                    <IconButton
                      label={t('app/activateUser')}
                      className={classes.backgroundGreen}
                      onClick={() => updateUserStatus(user.client, user.id, false)}
                    >
                      <CheckCircleOutline />
                    </IconButton>
                  )}
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        </Loader>
      </Paper>
      <CustomerProfilePageCreateSubsidiaryUserButton handleCreated={reloadUsers} subsidiary={subsidiary} />
      {/* <CustomerProfilePageSetDocumentSubscriptionButton customerId={subsidiary.id} /> */}

      {editDialogUser && (
        <CustomerProfilePageUserEditDialog
          customerId={id}
          open
          userDetails={editDialogUser}
          onSaved={reloadUsers}
          onClose={handleCloseEditUserDialog}
          onFormReleased={handleCloseEditUserDialog}
        />
      )}
      {passwordDialogUser && (
        <UserSetPasswordDialog
          open
          onFormReleased={handleClosePasswordDialog}
          onClose={handleClosePasswordDialog}
          subtitle={passwordDialogUser?.name}
          userId={passwordDialogUser?.id}
        />
      )}
    </div>
  );
});

CustomerProfilePageSubsidiaryUsersList.displayName = 'CustomerProfilePageSubsidiaryUsersList';

export default withThemeProps(themeSettings)(CustomerProfilePageSubsidiaryUsersList);
