import Button from '@material-ui/core/Button';
import React from 'react';
import useTranslateWithFallback from '../../../hooks/useTranslateWithFallback';
import CustomerProfilePageCreateSubsidiaryUserDialog from './CustomerProfilePageCreateSubsidiaryUserDialog';
import { SubsidiaryUserCreateFormValues, useSubsidiaryUserCreateHandler } from './useSubsidiaryUsersCreateHandler';

export interface CustomerProfilePageCreateSubsidiaryUserButtonProps {
  handleCreated?: () => void;
  subsidiary: {
    externals: string[];
    id: string;
    name: string;
    officeName: string;
  };
}

const CustomerProfilePageCreateSubsidiaryUserButton: React.FC<CustomerProfilePageCreateSubsidiaryUserButtonProps> = ({
  handleCreated,
  subsidiary,
}) => {
  const [open, setOpen] = React.useState(false);
  const createUser = useSubsidiaryUserCreateHandler();
  const t = useTranslateWithFallback();
  const handleClose = React.useCallback(() => setOpen(false), [setOpen]);
  const handleSave = React.useCallback(
    async (userData: SubsidiaryUserCreateFormValues) => {
      createUser({ ...userData, username: userData.email, subsidiary: subsidiary.id })
        .then(() => {
          if (handleCreated) {
            handleCreated();
          }
        })
        .finally(() => handleClose());
    },
    [createUser, handleClose, handleCreated, subsidiary.id],
  );

  return (
    <>
      <Button style={{ marginTop: '20px' }} variant='contained' color='primary' onClick={() => setOpen(true)}>
        {t('app/addUser')}
      </Button>
      <CustomerProfilePageCreateSubsidiaryUserDialog
        open={open}
        onSave={handleSave}
        onFormReleased={handleClose}
        onClose={handleClose}
        subsidiary={subsidiary}
      />
    </>
  );
};

CustomerProfilePageCreateSubsidiaryUserButton.displayName = 'CustomerProfilePageCreateSubsidiaryUserButton';

export default CustomerProfilePageCreateSubsidiaryUserButton;
