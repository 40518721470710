import { createStyles, makeStyles } from '@material-ui/core/styles';
import SectionHeadline from '@sprinx/react-mui-components/SectionHeadline';
import { StandardDivProps } from '@sprinx/react-mui-components/StandardDiv';
import withThemeProps from '@sprinx/react-mui-components/withThemeProps';
import clsx from 'clsx';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { isMasterScopeState } from '../../../api/appState';
import { shoppingCustomerSelector } from '../../../api/shopping';
import useTranslateWithFallback from '../../../hooks/useTranslateWithFallback';
import CustomerProfilePageMasteUsersList from './CustomerProfilePageMasterUsersList';

export type CustomerProfilePageMasteUsersProps = StandardDivProps<CustomerProfilePageMasteUsersClassKey>;

export type CustomerProfilePageMasteUsersClassKey = 'root';

const themeSettings = { name: 'CustomerProfilePageMasteUsers' };
const useStyles = makeStyles(
  // (theme: Theme) =>
  createStyles<CustomerProfilePageMasteUsersClassKey, {}>({
    root: {},
  }),
  themeSettings,
);

const CustomerProfilePageMasteUsers = React.forwardRef<HTMLDivElement, CustomerProfilePageMasteUsersProps>(
  ({ className, classes: pClasses }, ref) => {
    const classes = useStyles({ classes: pClasses });

    // const places = useRecoilValue(shoppingCustomerDeliveryPlacesSelector);
    const customer = useRecoilValue(shoppingCustomerSelector);
    const isMasterScope = useRecoilValue(isMasterScopeState);
    const t = useTranslateWithFallback();
    // console.log('places', places, 'customer', customer, 'isMasterScope', isMasterScope);

    return isMasterScope ? (
      <div ref={ref} className={clsx(classes.root, className)}>
        <SectionHeadline title={t('customerProfilePage/masterUsers')} />

        {customer && <CustomerProfilePageMasteUsersList sidiary={customer} />}
      </div>
    ) : null;
  },
);

CustomerProfilePageMasteUsers.displayName = 'CustomerProfilePageMasteUsers';

export default withThemeProps(themeSettings)(CustomerProfilePageMasteUsers);
