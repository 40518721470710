import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/Edit';
import Loader from '@sprinx/react-loader/Loader';
import DotJoined from '@sprinx/react-mui-components/DotJoined';
import IconButton from '@sprinx/react-mui-components/IconButtonWithTooltip';
import List from '@sprinx/react-mui-components/ListPretty';
import { StandardDivProps } from '@sprinx/react-mui-components/StandardDiv';
import withThemeProps from '@sprinx/react-mui-components/withThemeProps';
import PriceFieldPreviewNaked from '@sprinx/react-mui-fields/PriceFieldPreviewNaked';
import clsx from 'clsx';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { currencyState } from '../../../api/appState';
import useTranslateWithFallback from '../../../hooks/useTranslateWithFallback';
import CustomerProfilePageCreateMasterUserButton from './CustomerProfilePageCreateMasterUserButton';
import CustomerProfilePageUserEditDialog from './CustomerProfilePageUserEditDialog';
import useSidiaryUsersList, { SidiaryUser } from './useSubsidiaryUsersList';

export interface CustomerProfilePageMasteUsersListProps
  extends StandardDivProps<CustomerProfilePageMasteUsersListClassKey> {
  sidiary: {
    id: string;
    name: string;
  };
}

export type CustomerProfilePageMasteUsersListClassKey = 'root' | 'actions';

const themeSettings = { name: 'CustomerProfilePageMasteUsersList' };
const useStyles = makeStyles(
  createStyles<CustomerProfilePageMasteUsersListClassKey, {}>({
    root: {
      border: '1px solid #E0E0E0',
      padding: '20px',
      borderRadius: '10px',
      backgroundColor: '#f3f3f3',
    },
    actions: {
      display: 'flex',
    },
  }),
  themeSettings,
);

const CustomerProfilePageMasteUsersList = React.forwardRef<HTMLDivElement, CustomerProfilePageMasteUsersListProps>(
  ({ className, classes: pClasses, sidiary }, ref) => {
    const classes = useStyles({ classes: pClasses });
    const { id, name } = sidiary;
    const [loading, reloadUsers, users] = useSidiaryUsersList(id, '@testing.sprinx.cz');
    const currency = useRecoilValue(currencyState);

    const [editDialogUser, setEditDialogUser] = React.useState<SidiaryUser | undefined>(undefined);
    const handleCloseEditUserDialog = React.useCallback(() => setEditDialogUser(undefined), [setEditDialogUser]);
    const t = useTranslateWithFallback();

    return (
      <div ref={ref} className={clsx(classes.root, className)}>
        <Typography variant='h6'>{name}</Typography>

        <Paper>
          <Loader loading={loading}>
            <List>
              {(users && Array.isArray(users) ? users : []).map((user, idx) => (
                <ListItem key={idx}>
                  <ListItemText
                    primary={user.name}
                    secondary={
                      <DotJoined>
                        {user.username}
                        {/* {user.email} */}
                        {user.orderTotalLimit ? (
                          <>
                            Limit <PriceFieldPreviewNaked currency={currency} value={user.orderTotalLimit} />
                          </>
                        ) : null}
                      </DotJoined>
                    }
                  />
                  <ListItemSecondaryAction className={classes.actions}>
                    <IconButton
                      label={t('customerProfilePage/changeData/label')}
                      onClick={() => setEditDialogUser(user)}
                    >
                      <EditIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </List>
          </Loader>
        </Paper>
        <CustomerProfilePageCreateMasterUserButton handleCreated={reloadUsers} sidiary={sidiary} />

        {editDialogUser && (
          <CustomerProfilePageUserEditDialog
            customerId={id}
            isMaster={true}
            open
            userDetails={editDialogUser}
            onSaved={reloadUsers}
            onClose={handleCloseEditUserDialog}
            onFormReleased={handleCloseEditUserDialog}
          />
        )}
      </div>
    );
  },
);

CustomerProfilePageMasteUsersList.displayName = 'CustomerProfilePageMasteUsersList';

export default withThemeProps(themeSettings)(CustomerProfilePageMasteUsersList);
