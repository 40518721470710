import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { StandardDivProps } from '@sprinx/react-mui-components/StandardDiv';
import withThemeProps from '@sprinx/react-mui-components/withThemeProps';
import clsx from 'clsx';
import React from 'react';
import Page from '../../@sprinx/react-web/Page';
import PageHeadline from '../../@sprinx/react-web/PageHeadline';
import CustomerProfileNavigation from '../../components/CustomerProfileNavigation';
import useTranslateWithFallback from '../../hooks/useTranslateWithFallback';
import CustomerProfilePageCompanyPreview from './components/CustomerProfilePageCompanyPreview';
import CustomerProfilePageMasteUsers from './components/CustomerProfilePageMasterUsers';
import CustomerProfilePageSubsidiaryUsers from './components/CustomerProfilePageSubsidiaryUsers';
import CustomerProfilePageUserPreview from './components/CustomerProfilePageUserPreview';

export type CustomerProfilePageLayoutProps = StandardDivProps<CustomerProfilePageLayoutClassKey>;

export type CustomerProfilePageLayoutClassKey = 'root' | 'cartContainer';

const themeSettings = { name: 'CustomerProfilePageLayout' };
const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles<CustomerProfilePageLayoutClassKey, {}>({
      root: {},
      cartContainer: {
        display: 'flex',
        alignItems: 'stretch',
        [theme.breakpoints.down('sm')]: {
          flexWrap: 'wrap',
        },
      },
    }),
  themeSettings,
);

const CustomerProfilePageLayout = React.forwardRef<HTMLDivElement, CustomerProfilePageLayoutProps>(
  ({ className, classes: pClasses }, ref) => {
    const classes = useStyles({ classes: pClasses });
    const t = useTranslateWithFallback();

    return (
      <Page
        ref={ref}
        className={clsx(classes.root, className)}
        pageTitle={t('customerProfilePage/title')}
        breadcrumbs={<CustomerProfileNavigation />}
      >
        <PageHeadline>{t('customerProfilePage/title')}</PageHeadline>
        <div className={classes.cartContainer}>
          {/* VEDLE SEBE */}
          <CustomerProfilePageUserPreview />
          <CustomerProfilePageCompanyPreview />
        </div>
        <CustomerProfilePageMasteUsers />
        <CustomerProfilePageSubsidiaryUsers />
        {/* TODO: Subsidiaries (nastaveni pobocek) */}
      </Page>
    );
  },
);

CustomerProfilePageLayout.displayName = 'CustomerProfilePageLayout';

export default withThemeProps(themeSettings)(CustomerProfilePageLayout);
