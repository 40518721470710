import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/CloseTwoTone';
import AppHeader from '../MainLayout/components/AppHeader';
import NavigationLinks from '../../components/NavigationLinks';
import AppFooter from './components/AppFooter';

export type InfoLayoutProps = {
  children: React.ReactNode;
};

const drawerWidth = 240;

const useStyles = makeStyles(
  (theme) => ({
    root: {
      marginTop: 'auto',
      minHeight: '100vh',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    content: {
      display: 'flex',
      flexGrow: 1,
      height: '100%',
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      padding: '20px !important',
    },
    drawerPaper: {
      width: drawerWidth,
      border: 'none',
      boxShadow: 'none',
      padding: theme.spacing(0, 0),
      overflowY: 'unset',
      flexGrow: 1,
    },
    navLinksWrapper: {
      display: 'flex',
      flexDirection: 'column',
      padding: '20px',
      gap: '20px',
    },
    toolbar: {},
  }),
  { name: 'AppFooter' },
);

const InfoLayout: React.FC<InfoLayoutProps> = ({ children }) => {
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();
  useTheme();
  const handleDrawerToggle = React.useCallback((v) => {
    setOpen(typeof v === 'boolean' ? v : (prev) => !prev);
  }, []);

  return (
    <div className={classes.root}>
      <AppHeader handleDrawerToggle={handleDrawerToggle} />
      <div className={classes.content}>
        <Drawer
          className={classes.drawer}
          variant='temporary'
          classes={{
            paper: classes.drawerPaper,
          }}
          open={open}
        >
          <div className={classes.toolbar}>
            <IconButton onClick={() => handleDrawerToggle(false)}>
              <CloseIcon />
            </IconButton>
          </div>
          <div>
            <NavigationLinks className={classes.navLinksWrapper} isAsideLayout />
          </div>
        </Drawer>
        {children}
      </div>
      <AppFooter />
    </div>
  );
};

export default InfoLayout;
