import Button from '@material-ui/core/Button';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { StandardDivProps } from '@sprinx/react-mui-components/StandardDiv';
import withThemeProps from '@sprinx/react-mui-components/withThemeProps';
import clsx from 'clsx';
import React from 'react';
import { Link as RouterLink, useRouteMatch } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { isMasterScopeState } from '../../api/appState';
import theme from '../../theme';
import { RouteUrlType } from '../../routesBuilder';
import useRouteUrlFactory from '../../hooks/useRouteUrlFactory';
import { customerProfileNavigationItems } from '../../config';
import useTranslateWithFallback from '../../hooks/useTranslateWithFallback';

export type CustomerProfileNavigationProps = StandardDivProps<CustomerProfileNavigationClassKey>;

export type CustomerProfileNavigationClassKey = 'root' | 'button' | 'selected';

const themeSettings = { name: 'CustomerProfileNavigation' };
const useStyles = makeStyles(
  createStyles<CustomerProfileNavigationClassKey, {}>({
    root: {},
    button: {},
    selected: {
      backgroundColor: theme.palette.grey[200],
    },
  }),
  themeSettings,
);

type CustomerProfileNavigationItem = {
  exactMatchToBeSelected?: boolean;
  isMasterScopeOnly?: boolean;
  label: string;
  type: RouteUrlType;
};

const wholeNavigation: CustomerProfileNavigationItem[] = [
  {
    type: 'customerProfile',
    label: 'customerProfilePage/title',
    exactMatchToBeSelected: true,
  },
  {
    type: 'customerProfileOrders',
    label: 'customerProfileOrdersPage/title',
  },
  {
    type: 'customerProfileInvoices',
    label: 'customerProfileInvoicesPage/title',
    // isMasterScopeOnly: true,
  },
  {
    type: 'customerProfileCreditNotes',
    label: 'customerProfileCreditNotesPage/title',
    // isMasterScopeOnly: true,
  },
  {
    type: 'customerProfileInvoicesAndCreditNotes',
    label: 'customerProfileInvoicesAndCreditNotesPage/title',
    // isMasterScopeOnly: true,
  },
  {
    type: 'customerProfileDocuments',
    label: 'customerProfileDocumentsPage/title',
  },
  {
    type: 'customerProfileDeliveryNotes',
    label: 'customerProfileDeliveryNotesPage/title',
    // isMasterScopeOnly: true,
  },
  {
    type: 'customerProfileShoppingLists',
    label: 'customerProfileShoppingListsPage/title',
  },
  {
    type: 'cartsApproval',
    label: 'customerProfileOrdersConfirmationPage/title',
  },
];

const navigation: CustomerProfileNavigationItem[] = wholeNavigation.filter((item) =>
  customerProfileNavigationItems.some((x) => x === item.type),
);

const CustomerProfileNavigation = React.forwardRef<HTMLDivElement, CustomerProfileNavigationProps>(
  ({ className, classes: pClasses }, ref) => {
    const classes = useStyles({ classes: pClasses });
    const t = useTranslateWithFallback();
    const isMasterScope = useRecoilValue(isMasterScopeState);
    const { url } = useRouteMatch();
    const routeUrlFactory = useRouteUrlFactory();
    const visibleNavigation = navigation.filter((item) => !item.isMasterScopeOnly || isMasterScope);

    return (
      <div ref={ref} className={clsx(classes.root, className)}>
        {visibleNavigation.map(({ type, label, exactMatchToBeSelected }) => {
          const href = routeUrlFactory(type);
          const isSelected = exactMatchToBeSelected ? url === href : url.startsWith(href);

          return (
            <Button
              key={href}
              className={clsx(classes.button, isSelected && classes.selected)}
              component={RouterLink}
              to={href}
            >
              {t(label)}
            </Button>
          );
        })}
      </div>
    );
  },
);

CustomerProfileNavigation.displayName = 'CustomerProfileNavigation';

export default withThemeProps(themeSettings)(CustomerProfileNavigation);
